.rangeslider {
  border-radius: 4px;
}
.rangeslider__fill {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--primary) !important;
}
.rangeslider__handle {
  background-color: var(--primary) !important;
  border: 1px solid var(--paper) !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  left: -5px !important;
}
.rangeslider-vertical {
  margin-top: 0;
  margin-bottom: 0;
}
