@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: #2459ad;
    --primary-light: #2b6bd1;
    --secondary: #edf4ff;
    --secondary-light: #cce3ff;
    --tertiary: #ececec;
    --tertiary-light: #e0e0e0;
    --accent1: #ec6e00;
    --accent2: #03adf7;
    --text: #22313e;
    --text2: #80888f;
    --alert: #d01426;
    --background: #f4f4f4;
    --paper: #ffffff;
    --stroke: #dedede;
    --placeholder: #b4b8bc;
    --disabled: #f9f9f9;
    --note: #fdf9e6;
    --badge: #dc2626;
    --success1: #40d124;
    --success2: #e6fee1;
    --information1: #2563eb;
    --information2: #e3f3ff;
    --error1: #ff0000;
    --error2: #ffe6e6;
    --warning1: #ffc119;
    --warning2: #fff6dd;
    --1: 0 1px 1px #22222240;
    --2: 0 2px 2px #22222233;
    --3: 0 -1px 15px #a2a2a240;
    --4: 0 3px 8px #8484863d;
    --5: 0 0 10px #34343414;
    --6: 0 1px 30px #3899f333;
    --7: 0 1px 2px #00000033;
    --8: 0 0 4px 0 #cccccc;
    --9: 0 1px 3px #53535333;
    --10: 0 3px 10px #34343414;
    --11: 0 0 5px #79adff;
    --12: 0 4px 6px #2c2c2c0a;
    --13: -1px 0 5px 0 #0000001f;
    --15: 0 4px 20px #4a4a4e29;
    --16: 0 4px 12px #2459ad80;
    --17: 0 1px 10px #877f7f40;
    --18: 0 1px 8px #3899f333;
    --19: 0 1px 1px 0 #2222223d;
    --20: 0 1px 8px #1d1d1d40;
    --21: 0 1px 1px #2459ad40;
    --22: 0 2px 4px #00000026;
    --23: 0 2px 5px -1px #32325d33, 0 1px 3px -1px #0000000d;
    --24: 0 4px 16px #2c2c2c14;
  }
  body {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: var(--text);
    background-color: var(--background);
    min-width: 280px;
  }
  button,
  a,
  input,
  textarea {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    cursor: default;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    appearance: textfield;
    -o-appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    -ms-progress-appearance: textfield;
  }
}

#nprogress .bar {
  background: var(--primary) !important;
}
