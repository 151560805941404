.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-pagination-bullet {
  background-color: var(--paper);
  opacity: 1;
  margin: 0 !important;
}
.swiper-pagination-custom .swiper-pagination-bullet {
  background-color: var(--stroke);
}
.swiper-pagination-custom .swiper-pagination-horizontal {
  bottom: 0;
}
.swiper-pagination-bullet:not(:first-child) {
  margin-left: 5px !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primary);
}
